import React from 'react'
import { Helmet } from 'react-helmet';


export default function Contact() {
    return (
        <div className='contactPage'>
            <Helmet>
                <title>C-Elagage - Nous contacter</title>
                <meta name="description" content="Contacter l'entreprise C-Elagage à Vence pour un devis gratuit, par téléphone ou par email , ouvert du Lundi au Vendredi de 8h à 18h sans interruption" />
            </Helmet>
            <div className='Form'>
                <h1 className='animated-text'>
                    Besoin d'un devis gratuit  <br />
                    Contactez-Nous
                </h1>
                <div className='contactBloc'>
                    <div className='mesContacts'>
                        <div className='telText'>
                            <h2>Par téléphone pour un premier échange </h2>
                            <p>Texte</p>
                        </div>
                        <div className='Téléphone'>
                            <span>04-93-58-21-91</span>
                        </div>
                        <div className='mailText'>
                            <h2> Ou par mail</h2>
                        </div>
                        <div className='mailMail'>
                            <span>sarl-c.elagage@wanadoo.fr</span>
                        </div>
                    </div>
                    
                </div>
               
                
            </div>
        </div>
        );
    }
 

      