import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = ({ isOpen }) => {
    return (
        <div className={`navigation ${isOpen ? 'open' : ''}`}>
            <NavLink to="/">ACCUEIL</NavLink>
            <NavLink to="/elagage">ÉLAGAGE</NavLink>
            <NavLink to="/abattage">ABATTAGE</NavLink>
            <NavLink to="/debroussaillage">DÉBROUSSAILLAGE/ENTRETIEN</NavLink>
            <NavLink to="/creation">CRÉATION</NavLink>
            <NavLink to="/contact">NOUS CONTACTER</NavLink>
        </div>
    );
}

export default Navigation;
