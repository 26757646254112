import React, { useState, useEffect } from 'react';
import Escalier from '../../Assets/images/escalier.webp'
import Petanque from '../../Assets/images/petanque.webp'
import AlleeGravier from '../../Assets/images/allee-gravier.webp'
import AlleeTerre from '../../Assets/images/allee-terre.webp'
import Pot from '../../Assets/images/pot.webp'
import MurPierre from '../../Assets/images/murpierre.webp'
import Livraison from '../../Assets/images/livraison-olivier.webp'

const SlideShow = () => {
  const [images] = useState([
    MurPierre, 
    AlleeGravier,
    Escalier,
    AlleeTerre,
    Petanque,
    Pot,
    Livraison,
  ]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);

  return (
    <div className="image-carousel">
      <img src={images[currentImageIndex]} alt={`Images ${currentImageIndex + 1}`} />
    </div>
  );
};

export default SlideShow ;