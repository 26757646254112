import React from 'react'
import { Helmet } from 'react-helmet';
import Foret from '../Assets/images/abattage2.webp'
import Degagement from '../Assets/images/abattage.webp'
import Grue from '../Assets/images/grue1.webp'
import GrosArbre from '../Assets/images/gros-arbre.webp'
import Souche from '../Assets/images/souche.webp'
import TasBois from '../Assets/images/table.webp'
import { NavLink } from 'react-router-dom';

export default function Abattage()  {
    return (
        <div className='Abattage'>
            <Helmet>
                <title>C-Elagage | Abattage à Vence et dans les Alpes maritimes</title>
                <meta name="description" content="L'artisan élagueur C-Elagage intervient rapidement dans l'abattage d'arbres, équipé de camions grues de levage pour évacuer les arbres morts ou dangereux" />
            </Helmet>
            <div className='Bloc1Abattage'>
                <h1 className="title">Pourquoi faire appel à un élagueur professionnel comme C-ELAGAGE pour abattre un arbre ?</h1>
            </div>
            <div className='Bloc2Abattage'>
                <div className='Gestion'>
                    <h2>Gestion Forestière :</h2>
                    <p>Pour maintenir un équilibre écologique et favoriser la régénération des forêts, certains arbres doivent être abattus pour permettre aux autres de croître plus sainement.</p>
                    <img src={Foret} alt="abattage en forêt" />
                </div>
                <div className='Aménagement'> 
                    <h2>Aménagement du territoire :</h2>
                    <p>Dans le cadre de projets de construction, d'urbanisation ou d'infrastructures, il peut être nécessaire d'abattre des arbres pour faire place à de nouvelles installations.</p>
                    <img src={Souche} alt="arbre géant" />
                </div> 
                <div className='Sécurité'>  
                    <h2>Sécurité publique :</h2>
                    <p>Les arbres malades, morts ou dangereux peuvent représenter un risque pour la sécurité publique s'ils se trouvent près des routes et lignes électriques.</p>
                    <img src={Degagement} alt="dégagement d'un arbre tombé sur route" />
                    <p>Certains arbres sont parfois très proches des habitations et nécessitent d'être abattus avant accident, nous sommes équipés de grandes grues et nacelles pour intervenir en toute sécurité</p>
                    <img src={Grue} alt="evacuation avec grue" />
                </div>  
                
                <div className='Récolte'>  
                    <h2>Recyclage du bois :</h2>
                    <p>Le bois récolté suite à l'abattage sera recyclé à des fins diverses, telles que la production de papier, de meubles, de bois de construction ou de chauffage.</p>
                    <img src={GrosArbre} alt="arbre géant" />
                    <p className='table'>Voici quelques exemples de recyclages du bois dans une seule photo : Tables en chêne, chaises, charpente</p>
                    <img src={TasBois} alt="Tas de bois" />
                </div>
            </div>
            <div className='transitionDebroussaillage'>
                <h3>C-ELAGAGE S'OCCUPE AUSSI DU DEBROUSSAILLAGE DANS LES ALPES MARITIMES</h3>
                <p>Vous avez exploré en profondeur notre section consacrée à l'abattage, une étape cruciale dans la gestion et l'entretien de vos espaces extérieurs. <br />
                    Maintenant, plongeons dans l'univers de l'entretien des jardins avec la taille des haies , le débroussaillage, une pratique tout aussi essentielle pour préserver la santé et la beauté de votre environnement.</p>
            </div>
            <div className='transition-page'>
                <NavLink to='/Debroussaillage'><span>Découvrez notre page dédiée au débroussaillage en cliquant ici</span></NavLink>
            </div>
        </div>
    )
}