import React from 'react'
import { Helmet } from 'react-helmet'
import CypresAvant from '../Assets/images/cypres.webp'
import CypresApres from '../Assets/images/taille-cypres.webp'
import DebroussaillageAvant from '../Assets/images/image1.webp'
import DebroussaillageApres from '../Assets/images/image2.webp'
import Luxe from '../Assets/images/jardinluxe.webp'
import Nacelle from '../Assets/images/nacelle.webp'
import { NavLink } from 'react-router-dom';

export default function Debroussaillage() {
    return(
        <div className='debroussaillage'>
            <Helmet>
                <title>C-Elagage - Débroussaillage et entretien des jardins à Vence</title>
                <meta name="description" content="C-Elagage travaille chez les particuliers et avec les communes de Vence et Cagnes sur Mer pour les débroussaillages difficiles et l'entretien des jardins" />
            </Helmet>
            <div className='Bloc1Debroussaillage'>
                <h1 className="title">Pourquoi faire appel à C-ELAGAGE pour le débroussaillage et l'entretien des jardins ?</h1>
            </div>
            <div className='Bloc2et3'>
                <div className='Bloc2Débroussaillage'>
                    <img src={Luxe} alt="jardin provençal" />
                    <p>Vous rêvez d'un beau jardin provençal bien entretenue, C-ELAGAGE s'occupe du débroussaillage, de la taille de toute vos haies et plantes diverses</p>
                </div>
                <div className='Bloc3Debroussaillage'>
                    <p>C-ELAGAGE a les outils nécessaires pour s'attaquer à de très grandes surfaces pour le débroussaillage et l'entretien. <br />
                    Cela nous permet une rapidité d'action lors de l'intervention pour occasionner le moins de gênes possible </p>
                    <img src={Nacelle} alt="nacelle" />
                </div>
            </div>
            <div className='Bloc4Débroussaillage'>
                <h2>NOS RÉALISATIONS</h2>
                <div className='photoDebroussaillage'>
                    <img src={DebroussaillageAvant} alt="débroussaillage avant" />
                    <img src={DebroussaillageApres} alt="débroussaillage après" />
                </div>
                <div className='texteDebroussaillage'>
                    <p>AVANT</p>
                    <p>APRES</p>
                </div>
                <div className='photoPin'>
                    <img src={CypresAvant} alt="cyprès avant taille" />
                    <img src={CypresApres} alt="cyprès après taille" />
                </div>
            </div>
            <div className='Bloc6Debroussaillage'>
                <h2>C-ELAGAGE S'OCCUPE ÉGALEMENT DE LA CRÉATION DE JARDINS</h2>
                <p>Après avoir méticuleusement dégagé et défriché les terrains sauvages, nous tournons désormais notre attention vers une autre dimension du jardinage : la création de jardins sur mesure.</p>
            </div>
            <div className='transition-page'>
                <NavLink to='/Creation'><span>Découvrez notre page dédiée à la création de jardins en cliquant ici</span></NavLink>
            </div>
        </div>
        
    )
}