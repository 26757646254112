import React from 'react'
import pinAvant from '../Assets/images/pin-avant .webp'
import pinApres from '../Assets/images/pin-apres.webp'
import palmierAvant from '../Assets/images/palmier-avant.webp'
import palmierApres from '../Assets/images/palmier-apres.webp'
import olivierAvant from '../Assets/images/olivier-avant.webp'
import olivierApres from '../Assets/images/olivier-apres.webp'
import eucaAvant from '../Assets/images/euca-avant.webp'
import eucaApres from '../Assets/images/euca-apres.webp'
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

export default function Elagage() {
    
    return (
        <div className='Elagage'>
            <Helmet>
                <title>C-Elagage | Élagage à Vence et dans les Alpes maritimes</title>
                <meta name="description" content="Situé en Provence Alpes Côte d'Azur, l'entreprise C-Elagage intervient dans les Alpes maritimes, outillé pour élaguer de grands arbres, grands chênes, pins" />
            </Helmet>
            <div className='Bloc1Elagage'>
                <h1 className="title">Pourquoi faire appel à un élagueur professionnel comme C-ELAGAGE pour élaguer un arbre ?</h1>
                <div className='TextElagage'>
                    <h2>Expertise et sécurité :</h2>
                    <p>Les élagueurs sont formés pour évaluer l'état des arbres, identifier les branches dangereuses et utiliser des techniques d'élagage appropriées. Leur expertise garantit un travail sécurisé et de qualité.</p>
                    <h2>Santé des arbres :</h2>
                    <p>L'élagage régulier permet de maintenir la santé des arbres en éliminant les branches mortes, malades ou endommagées. Cela favorise également la croissance des nouveaux rameaux et la forme esthétique de l'arbre.</p>
                    <h2>Prévention des risques :</h2>
                    <p>En élaguant les arbres de manière adéquate, on réduit les risques de chute de branches et de dommages aux propriétés voisines, ainsi que les risques pour la sécurité des personnes.</p>
                    <h2>Amélioration de l'esthétique :</h2>
                    <p>L'élagage permet de modeler les arbres pour qu'ils s'intègrent harmonieusement dans leur environnement, améliorant ainsi l'esthétique globale du paysage.</p>
                    <h2>Promotion de la croissance :</h2>
                    <p>Un élagage bien exécuté peut stimuler la croissance des arbres en éliminant les branches encombrantes et en favorisant l'accès à la lumière et à l'air pour les parties restantes de l'arbre.</p>
                </div>
            </div>
            <div className='Bloc2Elagage'>
                <div className='pin'>
                    <div className='avant-apres'>
                        <p>AVANT</p>
                        <p>APRES</p>
                    </div>
                    <div className='photoPin'>
                        <img src={pinAvant} alt="pin-avant" />
                        <img src={pinApres} alt="pin-après" />
                    </div>
                    <div className='textPin'>
                        <h3>ELAGAGE D'UN PIN PARASOL</h3>
                        <p>Le pin parasol, scientifiquement connu sous le nom de Pinus pinea, est une espèce d'arbre originaire du bassin méditerranéen. Il est facilement reconnaissable grâce à sa silhouette caractéristique en forme de parasol, avec un tronc droit et une couronne arrondie. <br />
                        L'élagage du pin parasol est nécessaire pour plusieurs raisons. D'une part, il permet de maintenir sa forme caractéristique en parasol, en taillant les branches basses qui pourraient compromettre cette forme. D'autre part, l'élagage peut favoriser la circulation de l'air à travers la couronne, réduisant ainsi le risque de maladies fongiques et de pourriture. Enfin, l'élagage peut être nécessaire pour éliminer les branches mortes ou malades, ce qui contribue à la santé globale de l'arbre.
                        </p>
                    </div>
                </div>
                <div className='palmier'>
                    <div className='avant-apres'>
                        <p>AVANT</p>
                        <p>APRES</p>
                    </div>
                    <div className='photoPalmier'>
                        <img src={palmierAvant} alt="palmier-avant" />
                        <img src={palmierApres} alt="palmier-après" />
                    </div>
                    <div className='textPalmier'>
                        <h3>TAILLER UN PALMIER </h3>
                        <p>Le palmier, de la famille des Arecaceae, est une espèce d'arbre emblématique des régions tropicales et subtropicales à travers le monde. Sa structure distinctive en forme de tronc non ramifié surmonté d'une couronne de feuilles en fait une caractéristique marquante de nombreux paysages. <br />
                        La taille des palmiers est essentiel pour maintenir leur santé et leur esthétique. Il peut impliquer la suppression des feuilles mortes ou malades, ainsi que la taille des racines pour éviter qu'elles ne perturbent les structures environnantes. La taille peut également être nécessaire pour éliminer les fleurs ou les fruits fanés, favorisant ainsi la croissance continue de l'arbre.
                        </p>
                    </div>
                </div>
                <div className='olivier'>
                    <div className='avant-apres'>
                        <p>AVANT</p>
                        <p>APRES</p>
                    </div>
                    <div className='photoOlivier'>
                        <img src={olivierAvant} alt="olivier avant" />
                        <img src={olivierApres} alt="olivier après" />
                    </div>
                    <div className='textOlivier'>
                        <h3>TAILLER UN OLIVIER</h3>
                        <p>L'olivier, ou Olea europaea, est un arbre de la famille des Oléacées, cultivé principalement pour ses fruits, les olives. Il se caractérise par un tronc noueux et tordu, une écorce grisâtre et des feuilles argentées persistantes. <br />
                        L'élagage des oliviers est essentiel pour plusieurs raisons. Il favorise la production de fruits en permettant à la lumière du soleil de pénétrer dans la couronne, en améliorant la circulation de l'air et en réduisant le risque de maladies. De plus, l'élagage peut aider à maintenir la forme de l'arbre, à éliminer les branches mortes ou malades, et à faciliter la récolte des olives.
                        </p>
                    </div>
                </div>
                <div className='eucalyptus' >
                    <div className='avant-apres'>
                        <p>AVANT</p>
                        <p>APRES</p>
                    </div>
                    <div className='photoEucalyptus' >
                        <img src={eucaAvant} alt="eucalyptus avant" />
                        <img src={eucaApres} alt="eucalyptus après" />
                    </div>
                    <div className='textEucalyptus'>
                        <h3>ELAGAGE D'UN EUCALYPTUS</h3>
                        <p>Les eucalyptus sont des arbres de la famille des Myrtaceae, originaires d'Australie, mais répandus dans de nombreuses régions du monde. Ils se distinguent par leur écorce colorée et fibreuse, leurs feuilles persistantes, aromatiques et riches en huiles essentielles, ainsi que par leurs fleurs blanches, roses ou rouges. <br />
                            L'élagage des eucalyptus est souvent nécessaire pour contrôler leur croissance rapide et leur forme. Cela peut impliquer la suppression des branches mortes, malades ou endommagées, ainsi que la taille pour favoriser la ramification et la densité de la couronne. L'élagage peut également être effectué pour réduire le risque de chute de branches et améliorer la sécurité dans les zones peuplées.
                        </p>
                    </div>
                </div>
            </div>
            <div className='transitionAbattage'>
                <h3>C-ELAGAGE S'OCCUPE AUSSI DE L'ABATTAGE DES ARBRES DANS LES ALPES MARITIMES</h3>
                <p>Alors que l'élagage des arbres vise à préserver leur santé, leur esthétique et leur sécurité, il arrive parfois qu'un arbre soit trop endommagé, malade ou simplement trop imposant pour être conservé en toute sécurité. C'est dans de telles circonstances que l'abattage devient nécessaire.
                   Tourner la page de l'élagage pour aborder l'abattage des arbres, c'est entrer dans une phase où les décisions sont plus radicales, mais tout aussi cruciales pour la gestion des espaces verts, la sécurité publique et la santé des écosystèmes. Dans cette nouvelle étape, nous explorerons les techniques, les précautions et les considérations environnementales qui entourent l'abattage des arbres, garantissant ainsi une approche équilibrée de la gestion arboricole.</p>
            </div>
            <div className='transition-page'>
                <NavLink to='/Abattage'><span>Découvrez notre page dédiée à l'abattage des arbres en cliquant ici</span></NavLink>
            </div>
        </div>
    )
}