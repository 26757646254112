import React from 'react'
import { useNavigate } from 'react-router-dom';
import LogoWebsiteCreator from "../../Assets/images/Logo2.png"

export default function Footer() {
    const navigate = useNavigate()
    return (
        <div className='footer'>
            <div className='topFooter'>
                <h2 className='Name-entreprise'>C ELAGAGE </h2> 
                <h3 className='phoneNumber'>04-93-58-21-91</h3>
                <span onClick={() => navigate("/Mentions")}>Mentions Légales / Politique de confidentalité</span>
            </div>
            <div className='bottomFooter'>
                <img src={LogoWebsiteCreator} alt="logo Website-Creator" width="15px" height="15px"/>
                <a href="https://website-creator.fr/">
                    <span>Site réalisé par l'entreprise Website Creator à La Gaude</span>
                </a>
            </div>
        </div>
    )
}