import React, { useState } from 'react';
import logoC from '../../Assets/images/logo-celagage.webp';
/*import { NavLink } from 'react-router-dom';*/
import Navigation from '../Navigation/Navigation';
import LogoFacebook from "../../Assets/images/facebook.png"
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="App-header">
            <div className='header-top'>
                <div className='entrepriseName'>
                    <img src={logoC} className='elagage-logo' alt="logo-entreprise" />
                    <p>C-ELAGAGE</p>
                    <img src={logoC} className='elagage-logo' alt="logo-entreprise" />
                </div>
                <div className="menu-button" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <Navigation isOpen={isMenuOpen} />
            </div>
            <div className='header-bottom'>
                <Navigation/>
            </div>
            
        </div>
    )
}

export default Header;