import { createBrowserRouter } from "react-router-dom";
import React from "react";
import App from "./App";
import Home from './Pages/Home';
import Elagage from "./Pages/Elagage";
import Abattage from "./Pages/Abattage";
import Debroussaillage from "./Pages/Debroussaillage";
import Creation from "./Pages/Creation";
import Contact from "./Pages/Contact";
import ErrorPage from "./Pages/ErrorPages";
import Mentions from "./Pages/Mentions";

export const router = createBrowserRouter([ 
    { 
        path: '/', 
        element: <App/>, 
        errorElement:<ErrorPage/>,
        children: [ 
            { 
                path: '', 
                element: <Home/>
            },
            {
                path: 'elagage',
                element: <Elagage/>
            }, 
            {
                path: 'abattage', 
                element: <Abattage/>
            },
            {
                path: 'debroussaillage',
                element: <Debroussaillage/>
            },
            {
                path: 'creation',
                element: <Creation/>
            },   
            {
                path: 'contact',
                element: <Contact/>
            },
            {
                path: 'mentions',
                element: <Mentions/>
            },
        ],
    },
]);

// Ajouter une fonction de rappel pour effectuer le défilement vers le haut
router.subscribe(({ location }) => {
    window.scrollTo(0, 0);
});
