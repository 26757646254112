import React from 'react';
import 'normalize.css';
import './App.scss';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { Outlet } from 'react-router-dom';

export default function App() {
  return (
    <div className="App">
      <Header  />
      <Outlet />
      <Footer />
    </div>
  );
}

