import React from 'react';
import { Helmet } from 'react-helmet';
import photo7 from '../Assets/images/image7.webp';
import logo1 from '../Assets/images/logo1.webp';
import Logo1Small from '../Assets/images/logo1small.webp'
import Tronconneuse from '../Assets/images/tronconneuse.webp'
import TronconneuseSmall from '../Assets/images/tronconneuse-small.webp'
import Camion from '../Assets/images/camiongrue.webp'
import Nacelle from '../Assets/images/nacelle1.webp'
import Broyeur from '../Assets/images/broyeur.webp'
import Minipelle from '../Assets/images/débroussailleuse.webp'


export default function Home({ toggleMenu, isMenuOpen }) {
  
  return (
    <div className='homePage-centrale'>
      <Helmet>
        <title>C-Elagage - Élagueur à Vence |  Abattage | Débroussaillage </title>
        <meta name="description" content="Élagueur depuis plus de 25 ans, C-Elagage intervient à Vence et alentours pour vos travaux d'élagage, abattage d'arbres, débroussaillage, entretien de jardins" />
        <meta property="og:title" content="C-Elagage - Élagueur à Vence |  Abattage | Débroussaillage"></meta>
        <meta property= "og:description" content="Élagueur depuis plus de 25 ans, C-Elagage intervient à Vence et alentours pour vos travaux d'élagage, abattage d'arbres, débroussaillage, entretien de jardins"/>
        <meta property="og:url" content="https://www.c-elagage.com"></meta>
      </Helmet>
      <div className='homepage-top'>
        <div className='bloc1Home'>
          <img src={photo7} alt="grue"/>
          <h1>Entreprise d'élagage et de débroussaillage dans les Alpes Maritimes</h1>
        </div>
        <div className='bloc2Home'>
          <div className='titleBloc2'>
            <img src={logo1} srcSet={Logo1Small} alt="élagueur" />
            <h2>Pourquoi faire appel à un élagueur professionnel comme C-Elagage ?</h2>
          </div>
          <div className='textBloc2'>
            <p>La société C-ELAGAGE a été créée en 1997, plus de 25 ans d'expérience au service des particuliers et des collectivités dans les Alpes Maritimes pour entretenir et protéger la nature qui nous entoure. <br />
              Forts de notre expertise et de notre passion pour la préservation de la beauté naturelle, nous offrons une gamme complète de services d'élagage, d'abattage, de débroussaillage et de création de jardins. <br />
              Que ce soit pour entretenir des jardins privés, des parcs publics ou des terrains commerciaux, notre équipe qualifiée et équipée travaille avec précision et professionnalisme pour répondre aux besoins de nos clients. <br />
              Nous sommes engagés à offrir des services de qualité, respectueux de l'environnement et sécuritaires, tout en garantissant la satisfaction de nos clients à chaque étape du processus. <br />
              Faites confiance à C-ELAGAGE pour transformer vos espaces extérieurs en véritables oasis de verdure.
            </p>
          </div>
        </div>
        <div className='bloc3Home'>
          <div className='titleBloc3'>
            <img src={Tronconneuse} srcSet={TronconneuseSmall} alt="tronconneuse" />
            <h2>Nos outils de travail pour une rapidité d'action sur les chantiers</h2>
          </div>
          <div className='textBloc3'>
            <p>Nous sommes fiers de notre engagement envers la préservation de l'environnement à chaque étape de notre travail d'élagage. <br /> 
               Nous comprenons l'importance de maintenir un équilibre entre la santé des arbres et le respect de l'écosystème qui les entoure. <br />
               C'est pourquoi nous utilisons uniquement des outils et des techniques qui répondent aux normes environnementales les plus strictes. <br />
               Notre équipe d'experts est équipée des dernières technologies en matière d'outillage d'élagage. <br />
               Nos tronçonneuses, sécateurs, ébrancheurs et autres équipements sont soigneusement sélectionnés pour leur efficacité, leur fiabilité et leur faible impact environnemental. <br />
               Nous investissons dans des outils qui minimisent les émissions de gaz et réduisent la pollution sonore, assurant ainsi un travail respectueux de l'environnement.
            </p>
          </div>
        </div>
      </div>

      <div className='homepage-middle'>
        <div className='bloc4Home'>
          <div className='grue'>
            <div className='outilsPhotos'>
              <img src={Camion} alt="camion-grue" />
            </div>
            <div className='textOutils'>
              <p>Un camion benne de plus de 7.5T équipé d'une grue de 25 mètres pour une rapidité d'évacuation des troncs d'arbres et des grosses souches</p>
            </div>
          </div>
          <div className='nacelle-chenille'>
            <div className='outilsPhotos'>
              <img className='outilsPhotos' src={Nacelle} alt="nacelle" />
            </div>
            <div className='textOutils'>
              <p>2 Nacelles à chenilles, de 14 mètres et 25 mètres permettant d'accéder à de grands arbres sur terrains difficiles</p>
            </div>
          </div>
          <div className='broyeurs'>
            <div className='outilsPhotos'>
              <img src={Broyeur} alt="broyeur" />
            </div>
            <div className='textOutils'>
              <p>2 broyeurs de végétaux silencieux pour différents résultats de broyat ce qui permet de les utiliser de façon diverses (litières d'animaux, mulshing, compost)</p>
            </div>
          </div>
          <div className='pelle'>
            <div className='outilsPhotos'>
              <img src={Minipelle} alt="minipelle" />
            </div>
            <div className=' textOutils'>
              <p>Une pelle de 4 Tonnes équipée d'une débroussailleuse pour un débroussaillage rapide et efficace </p>
            </div>
          </div>
        </div>
      </div>

      <div className='homepage-bottom'>
        <div className='bloc5Home'>
          <h2>Notre zone d'intervention</h2>
        </div>
        <p className='villes'>Entreprise d'élagage Nice - Entreprise d'élagage Villeneuve-Loubet - Entreprise d'élagage Cagnes sur Mer - Entreprise d'élagage Saint-Paul de Vence - Entreprise d'élagage Valbonne - Entreprise d'élagage Tourettes-sur-Loup - Entreprise d'élagage Antibes - Entreprise d'élagage Saint-Jean-Cap-Ferrat - Entreprise d'élagage Mougins - Entreprise d'élagage Mandelieu - Entreprise d'élagage Cannes - Entreprise d'élagage Carros - Entreprise d'élagage Saint-Laurent du Var - Entreprise d'élagage Grasse</p>
      </div>
    </div>
  );
}
