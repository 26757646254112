import React from 'react'
import { Helmet } from 'react-helmet';
import SlideShow from '../Components/SlideShow/SlideShow';
import { NavLink } from 'react-router-dom';
import Escalier from '../Assets/images/escalier.webp'
import Petanque from '../Assets/images/petanque.webp'
import AlleeGravier from '../Assets/images/allee-gravier.webp'
import AlleeTerre from '../Assets/images/allee-terre.webp'
import Pot from '../Assets/images/pot.webp'
import MurPierre from '../Assets/images/murpierre.webp'
import Livraison from '../Assets/images/livraison-olivier.webp'

export default function Creation() {
    return(
        <div className='creation'>
            <Helmet>
                <title>C-Elagage - Création de jardin et entretien des jardins à Vence</title>
                <meta name="description" content="Élagueur à Vence, création de jardin et entretien de jardins" />s
            </Helmet>
            <div className='Bloc1Creation'>
                <h1 className="title">Pourquoi faire appel à C-ELAGAGE pour la création de jardins ?</h1>
            </div>
            <div className='Bloc2Creation'>
                <h2>Création et aménagements de jardins et d'espaces verts</h2>
            </div>
            <div className='Bloc3Creation'>
                <div className='SlideShow'>
                    <SlideShow/>
                </div>
                <div className='texteCreation'>
                    <p>C-ELAGAGE c'est aussi une équipe de jardiniers paysagistes. C-ELAGAGE conçoit et aménage des espaces verts, jardins, terrasses, pelouse, des murs végétaux, arbres et arbustres auprès des particuliers mais pour les entreprises, les associations ou les collectivités. <br />
                       Pour l'aménagement de votre jardin, C-ELAGAGE vous accompagne du début à la fin de votre projet, de la conception jusqu'au choix des plantes, arbres et arbustres, pierres et autre matériaux nécessaires.
                    </p>
                </div>
            </div>
            <div className='Bloc4Creation'>
                <div className='photoList'>
                    <img src={AlleeGravier} alt="allée en gravier" />
                    <img src={MurPierre} alt="mur en pierre" />
                    <img src={AlleeTerre} alt="allée en terre" />
                    <img src={Escalier} alt="escalier" />
                    <img src={Petanque} alt="pétanque" />
                    <img src={Pot} alt="pot" />
                    <img src={Livraison} alt="livraison" />
                </div>
                <div className='texteCreation2'>
                    <p>C-ELAGAGE c'est aussi une équipe de jardiniers paysagistes. C-ELAGAGE conçoit et aménage des espaces verts, jardins, terrasses, pelouse, des murs végétaux, arbres et arbustres auprès des particuliers mais pour les entreprises, les associations ou les collectivités. <br />
                       Pour l'aménagement de votre jardin, C-ELAGAGE vous accompagne du début à la fin de votre projet, de la conception jusqu'au choix des plantes, arbres et arbustres, pierres et autre matériaux nécessaires.
                    </p>
                </div>
            </div>
            <div className='Bloc5Creation'>
                <NavLink to='/Contact'><span>Demander un devis pour l'aménagement de mon jardin</span></NavLink>
            </div>
        </div>
    )
}